<template>
  <div class="footer">
    <div v-if="showPdf1" id="ifadd">
      <i class="el-icon-close close" @click="close1"></i>
      <div id="pdfaa1"></div>
    </div>
    <div class="footerLeft">
      <p class="synopsis">
        *This includes all personal income, including salary, part-time pay,
        retirement investments, and rental properties. You do not need to
        include alimony, child support, or separate maintenance income unless
        you want to have it considered as a basis for repaying a loan.
      </p>
      <ul class="menu">
        <el-row class="menuList">
          <el-col :span="6">
            <li>
              <el-link style="color: #fff" @click="goRouter('howItWorks')"
                >HOW IT WORKS
              </el-link>
            </li>
          </el-col>
          <el-col :span="6">
            <li>
              <el-link style="color: #fff" @click="goRouter('whoWeAre')"
                >WHO WE ARE
              </el-link>
            </li>
          </el-col>
          <el-col :span="6">
            <li @click="goRouter('rates')">
              <el-link style="color: #fff">RATES</el-link>
            </li>
          </el-col>
          <el-col :span="6">
            <li @click="goRouter('contactUs')">
              <el-link style="color: #fff">CONTACT US</el-link>
            </li>
          </el-col>
        </el-row>
        <div class="menuList">
          <!-- <el-col :span="6">
            <li>
              <el-link
                style='color:#fff'
                @click="goRouter('siteMaps')">SITE MAP</el-link>
            </li>
          </el-col> -->
          <el-col :span="6">
            <li @click="goRouter('privacyPolicy')">
              <el-link style="color: #fff">PRIVACY POLICY</el-link>
            </li>
          </el-col>
          <el-col :span="6">
            <li @click="goRouter('termsOfUse')">
              <el-link style="color: #fff">TERMS OF USE </el-link>
            </li>
          </el-col>
          <el-col :span="6">
            <li>
              <el-link style="color: #fff" @click="goRouter('accessibility')"
                >ACCESSIBILITY
              </el-link>
            </li>
          </el-col>
        </div>
        <!-- <div class='menuList'>
          <el-col :span="6">
            <li>
              <el-link
                style='color:#fff'
                @click="goHttp">LEGAL</el-link>
            </li>
          </el-col>
        </div> -->
      </ul>
      <p class="text">Important Disclosures:</p>
      <p class="text">
        Zoomer Credit is a state-licensed lender and subject to each state’s
        applicable laws. Loans offered only to residents in states where
        permitted by law. To obtain credit, you must apply online and have a
        valid bank account and email address. Approval for credit and the amount
        for which you may be approved are subject to minimum income requirements
        and vary by state. By clicking on RATES and selecting your state, you
        can see eligible product(s) and the rates and term. If you are a
        returning customer, loan amounts and/or rates may vary.
      </p>
      <p class="license">
        <span style="width: 45%"
          >View our Missouri license
          <span class="style link" @click="getMissouriPDF">here</span>.
        </span>
        <span
          >View our Utah license
          <span class="style link" @click="getUtahPDF">here</span>.</span
        >
      </p>
      <p class="license">
        <span style="width: 45%"
          >View our Wisconsin license
          <span class="style link" @click="$router.push({ name: 'wisconsin' })"
            >here</span
          >.
        </span>
        <span
          >View our Idaho license
          <a
            style="color: #fff"
            href="https://www.finance.idaho.gov/"
            >here</a
          >
          .</span
        >
      </p>
      <p class="license">
        <span style="width: 45%"
          >View our Delaware license
          <a
            style="color: #fff"
            href="https://banking.delaware.gov/non-depository-institutions/"
            >here</a
          >.
        </span>
      </p>
      <p class="text">
        If you have any questions, please
        <span class="link style" @click="goRouter('contactUs')">
          contact us</span
        >.
      </p>
      <p class="text">
        <span style="font-size: 1.2em">NOTE</span>
        : Installment loans are an expensive form of credit. Our products are
        intended to meet your short-term financial needs. Our products are not
        intended to provide a solution for all credit or other financial needs.
        Alternative forms of credit, such as a credit card cash advance,
        personal loan, home equity line of credit, existing savings or borrowing
        from a friend or relative, may be less expensive and more suitable for
        your financial needs. Not all applications are approved; duration of
        approval process may vary. Credit limits/loan amounts are subject to
        further verification criteria and delays in providing the requested
        documentation/information will delay the processing and could terminate
        your application or the transaction. In some cases, we may not be able
        to verify your application information and may ask you to provide
        certain documents.
      </p>
      <p class="text">
        Traditional and non-traditional credit bureaus may be used as permitted
        by law. By submitting an application, you authorize us and our
        third-party service providers to verify information through these
        national databases. We may review your credit attributes to determine
        your eligibility and ability to repay. Based on the findings from the
        original credit inquiry, non-traditional data bureaus may seek
        additional information from the traditional credit bureaus. When this
        occurs, it is possible your credit report will show an official credit
        inquiry.
      </p>
      <p class="text">
        The finance fee charged for your loan will be clearly disclosed to you
        in terms of the dollar amount of interest and the APR according to the
        principles of Federal Truth in Lending law and regulations. For details
        on the specific rates/fees of your loan, please see your loan agreement,
        but we have provided examples on the
        <span class="link style" @click="goRouter('rates')"> RATES </span> page.
        Late fees and non-sufficient funds/returned item fees might apply as
        described in your loan agreement. Complete disclosures of APR, fees and
        payment terms are provided within your loan Agreement.
      </p>
      <p class="text">
        If approved, any requested funds will typically be deposited into your
        bank account the same business day; timing of funding may vary. The date
        and time the funds are made available to you by your bank is subject to
        your bank’s policies. For specific funding cut-off times.
      </p>
      <p class="text">
        Because we report your payment history to one or more credit bureaus,
        late or non-payment of your debt may negatively impact your credit
        rating. The exact impact of payment(s) and/or account activity to your
        credit score is unknown as your credit score is influenced by numerous
        contributing factors.
      </p>
      <p class="text">
        Refinancing may be available and is not automatic. Refinancing will
        result in additional charges. If you don’t make a payment on time we
        will attempt to contact you via one or more authorized methods. If you
        fail to repay in accordance with your terms, we may place or sell your
        debt with a third-party collection agency or other company that acquires
        and/or collects delinquent consumer debt. Be sure you fully understand
        the terms and conditions of your loan before signing your Agreement.
      </p>
      <p class="text">
        We use cookies to enhance your experience, click
        <span class="style link" @click="goRouter('privacyPolicy')">here</span>
        for more information.
      </p>
      <p class="text1" style="text-align: center">
        ©2021 Zoomer Credit
        <img src="@/assets/ZoomerCreditlogo.png" alt="" class="logo" />
        All Rights Reserved.
      </p>
    </div>
    <div v-if="documentShow" class="document">
      <img
        src="@/assets/Missouri.png"
        alt=""
        style="width: 100%; height: 100%"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      documentShow: false,
      documentShow1: false,
      showPdf: false,
      showPdf1: false,
    };
  },
  methods: {
    mlicenseShow() {
      this.documentShow = true;
    },
    close() {
      this.showPdf = false;
    },
    ulicenseShow() {
      this.documentShow1 = true;
    },
    close1() {
      this.showPdf1 = false;
    },
    goRouter(value) {
      this.$router.push({ name: value });
    },
    goHttp() {
      window.open("http://zoomercredit.com/legal");
    },
    getMissouriPDF() {
      this.$router.push({ name: "missouri" });
    },
    getUtahPDF() {
      this.$router.push({ name: "utah" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import  'pdfh5/css/pdfh5.css';
#ifadd {
  width: 80%;
  height: 100%;
  z-index: 999999;
  position: absolute;
}
.link {
  cursor: pointer;
  font-size: 1em;
}
.style {
  text-decoration: underline;
}
.document {
  position: absolute;
  width: 80%;
  height: 100%;
}
.close {
  position: absolute;
  top: 2%;
  right: 2%;
  font-size: 2em;
  z-index: 9999;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5% 10% 3% 10%;
  box-sizing: border-box;
  background: #3e5166;
  position: relative;
  .menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
  }
  .text {
    margin-bottom: 1em;
    line-height: 1.5em;
  }
  li {
    list-style: none;
    font-size: 1.25em;
    color: #fff;
    line-height: 2em;
  }
  .license {
    display: flex;
    margin-bottom: 1em;
  }
  .menuList {
    width: 100%;
    display: flex;
    li {
      margin-right: 10%;
    }
  }
  .footerLeft {
    // width: 40%;
    display: flex;
    flex-direction: column;
    .appName {
      font-size: 2em;
      color: #fff;
      margin-bottom: 2%;
    }
    .synopsis {
      font-size: 1.25em;
      color: #fff;
      line-height: 2em;
      margin-bottom: 2%;
    }
    p {
      font-size: 1.4em;
      color: #fff;
    }
  }
  .footerRight {
    width: 40%;
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 1.4em;
      margin-bottom: 2em;
    }
    ul {
      li {
        font-size: 1.25em;
        color: #fff;
        line-height: 2em;
      }
    }
    .phone {
      font-size: 1.25em;
      line-height: 2em;
      color: #fff;
      .number {
        color: #459eff;
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1366px) {
  .footer {
    padding: 8% 8%;
  }
  .text1 {
    margin-bottom: 1em;
    line-height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 15%;
      margin: 0 0.8em;
    }
  }
}
// 兼容手机
@media screen and (max-width: 768px) {
  .footer .footerLeft .synopsis {
    font-size: 1em;
    line-height: 1.5em;
  }
  .footer .footerLeft .appName {
    font-size: 1.5em;
  }
  .footer .footerLeft .label {
    font-size: 1em;
  }
  .menu {
    display: block;
  }
  .text1 {
    font-size: 12px !important;
    margin-bottom: 1em;
    line-height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 20%;
      margin: 0 0.8em;
    }
  }
}
</style>